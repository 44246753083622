
.live-room-manage {
    padding: 20px;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    background: #fff;

    .live-title {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        //margin-bottom: 80px;
    }
}

.no-data-table {
    height: 60px !important;
}

/deep/.el-table.table-live-student {
    color: #455A94;
    font-weight: 500;
    th {
        color: #8CA5C6;
        background-color: #F8F9FB!important;
    }
    .el-table__row {
        height: 60px;
    }
    .el-link {
        margin-right: 24px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.info-box {
    display: flex;
    align-items: center;

    .info-circle {
        width: 54px;
        height: 54px;
        border-radius: 50%;
    }

    .info-square {
        width: 100px;
        height: 100px;
        margin-right: 30px;
    }

    .info-name {
        width: 200px;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }
}

.op-content {
    display: flex;
    justify-content: center;

    .op-btn + .op-btn {
        margin-left: 20px;
    }

    .op-btn {
        width: 76px;
        height: 30px;
        background: rgba(75, 69, 255, 0.1);
        border: 1px solid #4b45ff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #4b45ff;
        cursor: pointer;

        &.danger {
            border: 1px solid #ff3e6c;
            background: rgba(255, 62, 108, 0.1);
            color: #ff3e6c;
        }
    }
}

.empty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 117px;

    .no-data {
        width: 388px;
        height: 326px;
    }

    .no-data-tips {
        margin: 58px 0 34px;
        color: #fff;
    }

    //.el-button {
    //    background: #4b45ff;
    //}
}

::v-deep .el-dialog {
    background: #070932;

    .el-dialog__title {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        position: relative;
        margin-left: 20px;

        &:before {
            position: absolute;
            content: "";
            width: 4px;
            height: 12px;
            background: #4b45ff;
            left: -15px;
            top: 2px;
        }
    }
}

.dialog-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dialog-title {
        font-size: 16px;
        color: #fff;
        margin-top: 18px;
    }

    .qr-content {
        width: 222px;
        height: 222px;
        margin: 30px 0 44px;

        .qrCodeDiv {
            height: 100%;
            width: 100%;
        }
    }
}
